import { useEffect, useState } from 'react';

// Hooks
import ScreenDetect from '../../../hooks/ScreenDetect';
import useReductionPotential from '../../../hooks/calculators/useReductionPotential';

// Components
import VehicleInputs from './VehicleInputs';
import ReductionOutputs from './ReductionOutputs';

const ReductionPotential = () => {
  //

  // State
  const [isVisible, setIsVisible] = useState(false);

  //

  // Hooks
  const { isMobile } = ScreenDetect();
  const {
    data: { reductionsCalculatorStep },
  } = useReductionPotential();

  //

  // Effects
  useEffect(() => {
    // Trigger animation after component mounts
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      style={{
        marginTop: '15vh',
        padding: isMobile ? '0px 15px' : '0px 30px 10vh 30px',
        color: 'white',
        display: 'flex',
        flexWrap: 'wrap',
        gap: isMobile ? '30px' : '60px',
        opacity: isVisible ? 1 : 0,
        transform: `translateY(${isVisible ? 0 : -80}px)`,
        transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
    >
      {/* Title Section */}
      <div
        style={{
          flex: '1 1 300px',
          minWidth: '300px',
          maxWidth: '100%',
          marginBottom: '30px',
        }}
      >
        <h1
          style={{
            maxWidth: '70%',
            fontSize: '40px',
            lineHeight: '1.2',
            margin: isMobile ? '0px' : '-10px 0px 0px 0px',
          }}
        >
          Reduction Engine
        </h1>

        <p
          id="calculator-description"
          style={{
            maxWidth: '70%',
            fontSize: '1.25rem',
            marginTop: '20px',
          }}
        >
          See how Clearly's Reduction Engine can help you cut emissions and save
          costs
        </p>
      </div>

      {reductionsCalculatorStep === 0 && <VehicleInputs />}

      {reductionsCalculatorStep === 1 && <ReductionOutputs />}
    </div>
  );
};

export default ReductionPotential;
