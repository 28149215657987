import React, { useEffect, useState } from 'react';
import ScreenDetect from '../../hooks/ScreenDetect';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import AnimatedDiv from '../../components/AnimatedDiv';
import TollboothDesktop from '../SVGComponent/TollboothDesktop';
import TollboothMobile from '../SVGComponent/TollboothMobile';
import RequestDemoButton from '../../views/requestDemoButton';

function PolicyFramework() {
  const { isMobile, isTab, isLaptop } = ScreenDetect();

  // Removed expandedContent state and related logic

  const [trafficSwitch, setTrafficSwitch] = useState(false);

  useEffect(() => {
    const trafficLight = setInterval(() => {
      setTrafficSwitch(!trafficSwitch);
    }, 7000);
    return () => clearInterval(trafficLight);
  }, [trafficSwitch]);

  return (
    <>
      <div
        className={`container container-home homepage-policy-legislative-frameworks pure-g`}
      >
        <div
          className={`${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-12-24' : ''
          }${isLaptop ? ' pure-u-15-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-14-24' : ''
          } left`}
        >
          <div className="header pure-u-1">
            <AnimatedDiv>
              <h1
                className={`${isMobile ? ' pure-u-24-24' : ''}${
                  isTab ? ' pure-u-24-24' : ''
                }${isLaptop ? ' pure-u-13-24' : ''}${
                  !isMobile && !isTab && !isLaptop ? ' pure-u-12-24' : ''
                } left`}
              >
                Decarbonisation Aligned with Global Standards
              </h1>
            </AnimatedDiv>
          </div>
          <div
            className={`${isMobile ? ' pure-u-24-24' : ''}${
              isTab ? ' pure-u-16-24' : ''
            }${isLaptop ? ' pure-u-10-24' : ''}${
              !isMobile && !isTab && !isLaptop ? ' pure-u-8-24' : ''
            } details`}
          >
            <AnimatedDiv>
              <div className={`content default`}>
                <p>
                  Powered by proprietary technology, Clearly's platform ensures
                  that emissions data and reduction insights align with leading
                  global standards (GLEC, GHG Protocol, ISO 14083 and more).
                </p>
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <p>
                    Seamlessly export your data and provide all your
                    stakeholders with trusted, compliant reports in no time.
                  </p>
                  <p>Reduce emissions and costs while remaining compliant.</p>
                  <NavLink to="/about">Read more about Clearly</NavLink>
                </motion.div>
              </div>
            </AnimatedDiv>
            {isMobile && (
              <motion.div
                className="policy-page-request-demo"
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: -120 }}
                transition={{ duration: 0.5 }}
              >
                <RequestDemoButton parentPage="Team" />
              </motion.div>
            )}
          </div>
        </div>
        <div
          className={`${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-11-24' : ''
          }${isLaptop ? ' pure-u-8-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-6-24' : ''
          } right`}
        >
          {!isMobile && <TollboothDesktop switchTrafficLight={trafficSwitch} />}
          {isMobile && <TollboothMobile switchTrafficLight={trafficSwitch} />}
        </div>
      </div>
    </>
  );
}

export default PolicyFramework;
