import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import { assets } from '../../assets/assets';
function PathRight() {
  const { ref: refTruck } = useParallax({ speed: 20 });
  const { ref: refVan } = useParallax({ speed: 30 });
  const { ref: refCar } = useParallax({ speed: 25 });
  return (
    <>
      <div className="path path-1">
        <img
          ref={refTruck}
          src={assets.truckPathRight}
          className="truck"
          alt="truck"
          loading="lazy"
        ></img>
      </div>
      <div className="path path-2">
        <img
          ref={refVan}
          src={assets.van}
          className="van"
          alt="van"
          loading="lazy"
        ></img>
      </div>
      <div className="path path-3">
        <img
          ref={refCar}
          src={assets.Car}
          className="car"
          alt="car"
          loading="lazy"
        ></img>
      </div>
    </>
  );
}
export default PathRight;
