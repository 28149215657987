import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useParallax } from 'react-scroll-parallax';
import GetScroll from '../../hooks/getScroll';
import ScreenDetect from '../../hooks/ScreenDetect';
import { assets } from '../../assets/assets';
import RequestDemoButton from '../../views/requestDemoButton';
function HomeLayeredSmall(props) {
  const { ref: refTruck } = useParallax({ speed: 20 });
  const { ref: refVan } = useParallax({ speed: 30 });
  const { ref: refCar } = useParallax({ speed: 25 });
  const { ref: layeredSectionParentRef, inView: inViewLayeredSectionParent } =
    useInView({
      rootMargin: '0px 0px -91.6%',
      threshold: 0,
    });
  const {
    ref: layeredSectionRef,
    inView: inViewLayeredSection,
    entry: entryForLayeredSectionRef,
  } = useInView({
    rootMargin: '0px 0px -91.6%',
    threshold: 0,
  });
  const [scrollPositionIfRefInView, setscrollPositionIfRefInView] =
    useState('');
  const headerTopOnHome = GetScroll();
  useEffect(() => {
    if (inViewLayeredSection) {
      setscrollPositionIfRefInView(
        entryForLayeredSectionRef.target.offsetTop - 91
      );
    }
  }, [inViewLayeredSection, entryForLayeredSectionRef?.target?.offsetTop]);
  const { isMobile, isTab, isLaptop } = ScreenDetect();
  return (
    <>
      <div
        className={`container container-home homepage-layered-section pure-g ${
          inViewLayeredSection && 'change'
        }`}
        ref={layeredSectionRef}
      >
        <div
          className={`left ${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-13-24' : ''
          }${isLaptop ? ' pure-u-16-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-18-24' : ''
          }`}
        >
          <div
            className={`all-details-container pure-g ${
              ((headerTopOnHome >= scrollPositionIfRefInView + 1200 &&
                (isTab || isMobile)) ||
                (!isTab && !isMobile)) &&
              ' showed'
            } ${inViewLayeredSectionParent && 'change'}`}
            ref={layeredSectionParentRef}
          >
            <div
              className={`all-content ${
                ((headerTopOnHome >= scrollPositionIfRefInView + 1400 &&
                  (isTab || isMobile)) ||
                  (!isTab && !isMobile)) &&
                ' showed'
              } ${isMobile ? ' pure-u-24-24' : ''}${
                isTab ? ' pure-u-16-24' : ''
              }${isLaptop ? ' pure-u-8-24' : ''}${
                !isMobile && !isTab && !isLaptop ? ' pure-u-5-24' : ''
              }`}
            >
              {(headerTopOnHome <= scrollPositionIfRefInView + 900 ||
                scrollPositionIfRefInView === '') && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="element element-1"
                >
                  <h3>Mobility</h3>
                  {(headerTopOnHome <= scrollPositionIfRefInView + 300 ||
                    scrollPositionIfRefInView === '') && (
                    <motion.p
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      Sensors, apps, satellites for GPS, route patterns, driver
                      behaviour, tyre pressure<span>...</span>
                    </motion.p>
                  )}
                </motion.div>
              )}
              {headerTopOnHome >= scrollPositionIfRefInView + 300 &&
                headerTopOnHome < scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="element element-2"
                  >
                    <h3>Operations</h3>
                    {headerTopOnHome <= scrollPositionIfRefInView + 600 && (
                      <motion.p
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        Sensors and databases for metrics and context of people
                        and package movement<span>...</span>
                      </motion.p>
                    )}
                  </motion.div>
                )}
              {headerTopOnHome > scrollPositionIfRefInView + 600 &&
                headerTopOnHome < scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className="element element-3"
                  >
                    <h3>Energy</h3>
                    <p>
                      Sensors and statements for the current usage and future
                      demand of energy supplies<span>...</span>
                    </p>
                    <motion.div
                      style={{ marginLeft: 0 }}
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: -130 }}
                      transition={{ duration: 0.5 }}
                      className={`layered-request-demo`}
                    >
                      <RequestDemoButton parentPage="Landing page" />
                    </motion.div>
                  </motion.div>
                )}
              {headerTopOnHome > scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    className={`element element-4 ${
                      inViewLayeredSectionParent && 'change'
                    }`}
                  >
                    <h3>Data fusion</h3>
                    <p>
                      Clearly is data-format and hardware agnostic. It
                      seamlessly integrates with your existing data sources,
                      apps and sensors. Plug and play with no heavy lifting and
                      overhead for your teams.
                    </p>
                    <p>
                      Data is cleansed, blended, and enriched with third party
                      and proprietary data to generate actionable insights.
                    </p>
                    <p>
                      <strong>
                        But wait there’s more to it<span>...</span>
                      </strong>
                    </p>
                  </motion.div>
                )}
            </div>
            {((headerTopOnHome <= scrollPositionIfRefInView + 1200 &&
              (isTab || isMobile)) ||
              (!isTab && !isMobile)) && (
              <div
                className={`all-visuals  ${isMobile ? ' pure-u-24-24' : ''}${
                  isTab ? ' pure-u-24-24' : ''
                }${isLaptop ? ' pure-u-13-24' : ''}${
                  !isMobile && !isTab && !isLaptop ? ' pure-u-18-24' : ''
                }`}
              >
                <div className="grid">
                  <img src={assets.mobileGrid} alt="grid" loading="lazy"></img>
                </div>
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 609 1100"
                >
                  {(headerTopOnHome <= scrollPositionIfRefInView + 900 ||
                    scrollPositionIfRefInView === '') && (
                    <motion.image
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      width="2385"
                      height="2362"
                      id="Blue"
                      transform="matrix(0.25 0 0 0.25 12.7876 290.1498)"
                      xlinkHref={assets.blueMobile}
                    />
                  )}
                  {headerTopOnHome >= scrollPositionIfRefInView + 300 &&
                    headerTopOnHome < scrollPositionIfRefInView + 900 && (
                      <motion.image
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        width="1802"
                        height="1656"
                        transform="matrix(0.25 0 0 0.25 91.7915 288.1302)"
                        xlinkHref={assets.yellowMobile}
                      />
                    )}
                  {headerTopOnHome > scrollPositionIfRefInView + 600 &&
                    headerTopOnHome < scrollPositionIfRefInView + 900 && (
                      <motion.image
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                        width="1907"
                        height="2080"
                        transform="matrix(0.25 0 0 0.25 0.3889 340.7525)"
                        xlinkHref={assets.greenMobile}
                      />
                    )}
                  {headerTopOnHome > scrollPositionIfRefInView + 900 &&
                    inViewLayeredSectionParent && (
                      <motion.image
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.8 }}
                        width="2435"
                        height="2370"
                        transform="matrix(0.25 0 0 0.25 0.2814 288.1826)"
                        xlinkHref={assets.dataFusionMobile}
                      />
                    )}
                </svg>
              </div>
            )}
          </div>
        </div>
        <div
          className={`paths right ${isMobile ? ' pure-u-24-24' : ''}${
            isTab ? ' pure-u-11-24' : ''
          }${isLaptop ? ' pure-u-8-24' : ''}${
            !isMobile && !isTab && !isLaptop ? ' pure-u-6-24' : ''
          }`}
        >
          <div className="path path-1">
            <img
              ref={refTruck}
              src={assets.truck}
              alt="truck"
              className="truck"
              loading="lazy"
            ></img>
            <img
              src={assets.truck}
              alt="truck"
              className={`truck second ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
          </div>
          <div className="path path-2">
            <img
              ref={refVan}
              src={assets.vanWithBlackBG}
              alt="van"
              className="van"
              loading="lazy"
            ></img>
            <img
              src={assets.vanWithBlackBG}
              alt="van"
              className={`van second ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
            <img
              src={assets.vanWithBlackBG}
              alt="van"
              className={`van third ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
          </div>
          <div className="path path-3">
            <img
              ref={refCar}
              src={assets.carWithBlackBackground}
              alt="car"
              className="car"
              loading="lazy"
            ></img>
            <img
              src={assets.carWithBlackBackground}
              alt="car"
              className={`car second ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
            <img
              src={assets.carWithBlackBackground}
              alt="car"
              className={`car third ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
            <img
              src={assets.carWithBlackBackground}
              alt="car"
              className={`car fourth ${
                headerTopOnHome >= scrollPositionIfRefInView + 900 &&
                inViewLayeredSectionParent &&
                'turn'
              }`}
              loading="lazy"
            ></img>
          </div>
          <div className="barrier">
            <img src={assets.barrier} alt="barrier" loading="lazy"></img>
          </div>
        </div>
      </div>
      {isMobile && (
        <div className="data-fusion-mobile-image">
          {((headerTopOnHome >= scrollPositionIfRefInView + 1200 &&
            (isTab || isMobile)) ||
            (!isTab && !isMobile)) && (
            <motion.img
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 0 }}
              transition={{ duration: 0.8 }}
              src={assets.barrierMobile}
              alt="barrier"
            ></motion.img>
          )}
        </div>
      )}
    </>
  );
}
export default HomeLayeredSmall;
