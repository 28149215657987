import React, { createContext, useState, useContext, useEffect } from 'react';

// Data options
export const REGION_OPTIONS = [
  { label: 'United States', value: 'usa' },
  { label: 'Europe', value: 'eu' },
  { label: 'United Kingdom', value: 'uk' },
];

export const UNIT_OPTIONS = [
  { label: 'Miles', value: 'mi' },
  { label: 'Kilometers', value: 'km' },
];

export const CURRENCY_OPTIONS = [
  { label: 'USD', value: 'usa' },
  { label: 'EUR', value: 'eu' },
  { label: 'GBP', value: 'uk' },
];

export const VEHICLE_TYPE_OPTIONS = [
  { label: '> 15 Tonnes ', value: 'class_8' },
  // { label: 'Class 7', value: 'class-7' },
  // { label: 'Class 6', value: 'class-6' },
  // { label: 'Class 5', value: 'class-5' },
  // { label: 'Class 4', value: 'class-4' },
  // { label: 'Class 3', value: 'class-3' },
  // { label: 'Class 2', value: 'class-2' },
  // { label: 'Class 1', value: 'class-1' },
];

export const FUEL_TYPE_OPTIONS = [
  { label: 'Diesel', value: 'diesel' },
  // { label: 'Biodiesel (B20)', value: 'b20' },
  // { label: 'Electric', value: 'electric' },
];

export interface LabelValuePair {
  label: string;
  value: string;
}

interface ReductionsCalculatorState {
  // Input Fields
  region: LabelValuePair;
  unit: LabelValuePair;
  currency: LabelValuePair;
  vehicleType: LabelValuePair;
  fuelType: LabelValuePair;
  numberOfVehicles: number;
  annualDistancePerVehicle: number;

  // Output Fields
  costReduction: number;
  emissionsReduction: number;
  costReductionPercentage: string;
  emissionsReductionPercentage: string;

  // Calculator meta
  reductionsCalculatorStep: number;
  isCalculatingReductionPotential: boolean;

  // Has the user clicked calculate once
  numberOfCalculationsForUser: number;
}

interface ReductionsCalculatorContextType {
  reductionsCalculatorsState: ReductionsCalculatorState;
  updateReductionsCalculatorsState: (
    newState: Partial<ReductionsCalculatorState>
  ) => void;
}

const ReductionsCalculatorContext = createContext<
  ReductionsCalculatorContextType | undefined
>(undefined);

const LOCAL_STORAGE_KEY = 'ReductionsCalculatorState';

const defaultState: ReductionsCalculatorState = {
  // Inputs
  region: REGION_OPTIONS[0],
  unit: UNIT_OPTIONS[0],
  currency: CURRENCY_OPTIONS[0],
  vehicleType: VEHICLE_TYPE_OPTIONS[0],
  fuelType: FUEL_TYPE_OPTIONS[0],
  numberOfVehicles: 100,
  annualDistancePerVehicle: 100000,

  // Outputs
  costReduction: 0,
  emissionsReduction: 0,
  costReductionPercentage: '0',
  emissionsReductionPercentage: '0',

  // Meta
  reductionsCalculatorStep: 0,
  isCalculatingReductionPotential: false,
  numberOfCalculationsForUser: 0,
};

export const ReductionsCalculatorsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [reductionsCalculatorsState, setState] =
    useState<ReductionsCalculatorState>(() => {
      // Try to load the state from localStorage on initial render
      if (typeof window !== 'undefined') {
        const storedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        return storedState ? JSON.parse(storedState) : defaultState;
      } else {
        return defaultState;
      }
    });

  useEffect(() => {
    // Save to localStorage whenever the state changes
    if (typeof window !== 'undefined') {
      localStorage.setItem(
        LOCAL_STORAGE_KEY,
        JSON.stringify(reductionsCalculatorsState)
      );
    }
  }, [reductionsCalculatorsState]);

  const updateReductionsCalculatorsState = (
    newState: Partial<ReductionsCalculatorState>
  ) => {
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <ReductionsCalculatorContext.Provider
      value={{ reductionsCalculatorsState, updateReductionsCalculatorsState }}
    >
      {children}
    </ReductionsCalculatorContext.Provider>
  );
};

export const useReductionsCalculatorsContext =
  (): ReductionsCalculatorContextType => {
    const context = useContext(ReductionsCalculatorContext);
    if (!context) {
      throw new Error(
        'useReductionsCalculatorContext must be used within a ReductionsCalculatorProvider'
      );
    }
    return context;
  };
