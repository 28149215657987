import { useFlags } from 'flagsmith/react';
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
function PageLinks(props) {
  const { isFooter, isMobile } = props;
  // const isFooter = props.isFooter;
  const location = useLocation();
  const currentPath = location.pathname;
  const Navigate = useNavigate();
  const [isCareersClicked, setIsCareersClicked] = useState(false);
  const flags = useFlags(['blog']);

  const handleAboutAndTeamClick = (link) => {
    Navigate(link);
  };

  const handleAboutUsClick = (e) => {
    e.preventDefault();
  };

  const handleCareersClick = () => {
    setIsCareersClicked(true);
  };

  const linkClicked = () => {
    setIsCareersClicked(false);
  };

  return (
    <>
      <ul className="page-links">
        {isFooter === 'yes' && (
          <li onClick={linkClicked}>
            <NavLink
              end
              className={({ isActive }) => (isActive ? 'active-link' : 'link')}
              to="/"
            >
              Home
            </NavLink>
          </li>
        )}
        <li onClick={linkClicked}>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : 'link')}
            to="/understand"
          >
            Understand
          </NavLink>
        </li>
        <li onClick={linkClicked}>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : 'link')}
            to="/optimise"
          >
            Optimise
          </NavLink>
        </li>
        <li onClick={linkClicked}>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : 'link')}
            to="/transition"
          >
            Transition
          </NavLink>
        </li>
        {flags.blog.enabled && (
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? 'active-link' : 'link')}
              to="/blog"
            >
              Blog
            </NavLink>
          </li>
        )}
        <li>
          <NavLink
            id="about-us"
            to="/about"
            style={{
              color:
                currentPath.includes('/team') ||
                currentPath.includes('/about') ||
                (isCareersClicked && isFooter === 'no')
                  ? '#FF7C7C'
                  : 'inherit',
            }}
            onClick={handleAboutUsClick}
          >
            About us
          </NavLink>

          <ul
            className={isFooter === 'yes' ? 'footer-dropdown' : 'dropdown-menu'}
            style={{
              position:
                isFooter === 'yes' || isMobile === 'yes'
                  ? 'relative'
                  : 'absolute',
              width: isMobile ? '275px' : '66px',
              margin: isMobile ? '30px 0px 0px 14%' : isFooter ? '' : 'auto',
            }}
          >
            <li
              className={isMobile ? 'dropdown-li-mobile' : 'dropdown-li'}
              onClick={() => handleAboutAndTeamClick('/team')}
            >
              <NavLink
                id="team"
                className={({ isActive }) =>
                  isActive ? 'active-link' : 'link'
                }
                to="/team"
                style={{
                  color: currentPath.includes('/team') ? '#FF7C7C' : 'inherit',
                  margin: isMobile ? '0px 0px 30px 0px' : '10px 5px',
                }}
              >
                Team
              </NavLink>
            </li>
            {!isMobile ? <hr /> : ''}
            <li
              className={isMobile ? 'dropdown-li-mobile' : 'dropdown-li'}
              onClick={() => handleAboutAndTeamClick('/about')}
            >
              <NavLink
                id="about"
                className={({ isActive }) =>
                  isActive ? 'active-link' : 'link'
                }
                to="/about"
                style={{
                  color: currentPath.includes('/about') ? '#FF7C7C' : 'inherit',
                  margin: isMobile ? '0px 0px 30px 0px' : '10px 0px 5px 0px',
                }}
              >
                About
              </NavLink>
            </li>
            {!isMobile ? <hr /> : ''}
            {isFooter === 'no' && (
              <li
                className={isMobile ? 'dropdown-li-mobile' : 'dropdown-li'}
                onClick={handleCareersClick}
              >
                <NavLink
                  id="careers"
                  className={({ isActive }) =>
                    isActive ? 'active-link' : 'link'
                  }
                  to="https://app.otta.com/companies/clearly?_gl=1*ikq3kq*_gcl_au*MTk0NzU4ODgyOC4xNzA4NTMyNjAy&_ga=2.54587435.1897338477.1710839600-1112123280.1708532602"
                  target="_blank"
                  style={{
                    color: isCareersClicked ? '#FF7C7C' : 'white',
                    margin: isMobile ? '0px 0px 0px 0px' : '10px 0px 5px 0px',
                  }}
                >
                  Careers
                </NavLink>
              </li>
            )}
            {isFooter === 'no' && !isMobile ? <hr /> : ''}
          </ul>
        </li>
        <li onClick={linkClicked}>
          <NavLink
            className={({ isActive }) => (isActive ? 'active-link' : 'link')}
            to="/contact"
          >
            Contact us
          </NavLink>
        </li>
        {isFooter === 'yes' && (
          <li onClick={handleCareersClick}>
            <NavLink
              className={({ isActive }) => (isActive ? 'active-link' : 'link')}
              to="https://app.otta.com/companies/clearly?_gl=1*ikq3kq*_gcl_au*MTk0NzU4ODgyOC4xNzA4NTMyNjAy&_ga=2.54587435.1897338477.1710839600-1112123280.1708532602"
              target="_blank"
            >
              Careers
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
}
export default PageLinks;
