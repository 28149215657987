import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import AnimatedDiv from '../components/AnimatedDiv';
import ScreenDetect from '../hooks/ScreenDetect';
import { assets } from '../assets/assets';
import RequestDemoButton from './requestDemoButton';

export default function AboutPage() {
  function isBelowLaptopWidth() {
    return window.innerWidth < 1280;
  }
  //checking device width for navigation switch
  const [isBelowLaptop, setisBelowLaptop] = useState(isBelowLaptopWidth());
  useEffect(() => {
    function handleWindowResize() {
      setisBelowLaptop(isBelowLaptopWidth());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const { isMobile } = ScreenDetect();
  return (
    <>
      <motion.div
        className="pure-g container about-us-page"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="main-section">
          <div className="pure-g">
            <div className=" pure-u-1-2 page-title">
              <AnimatedDiv>
                <h1>About us</h1>
              </AnimatedDiv>
            </div>
            <div className="pure-u-1-2 page-title">
              <AnimatedDiv>
                {!isBelowLaptop && <h2>Active in 9 Countries</h2>}
              </AnimatedDiv>
            </div>
          </div>
          <div className="pure-g page-content">
            <div className="pure-u-xl-7-24 pure-u-sm-24-24 left">
              <div className="page-caption">
                <AnimatedDiv>
                  <p>
                    Clearly was founded in 2021 with a mission to accelerate the
                    transportation industry to net zero emissions.
                  </p>
                  <p>
                    Road transportation accounts for 25% of the world's energy
                    consumption and carbon emissions, and requires $1.75tn
                    annually to reach set net zero goalsThis sector is complex
                    and its decarbonisation requires a collaborative effort
                    across multiple domains.
                  </p>
                  <p>
                    At Clearly, we understand the critical needs of fleet owners
                    and operators managing millions of shipments or trips daily.
                    They face immense challenges in accurately measuring
                    emissions and transitioning their operations in a
                    cost-effective manner. Using Big Data and Artificial
                    Intelligence, Clearly delivers granular emissions insights
                    across Scopes 1, 2, and 3. This enables companies to
                    accurately simulate emissions, optimise operations, and plan
                    profitable transitions.
                  </p>
                  <p>
                    Our software integrates data from a variety of sources
                    (vehicles, telematics, operations, and energy use) to
                    provide a comprehensive view. Supported by regulatory
                    frameworks, Clearly ensures fleet managers can meet
                    emissions targets while reducing costs and improving
                    operational efficiency.
                  </p>
                  <p>
                    <a
                      href="https://medium.com/pace-ventures/why-we-invested-in-clearly-4f07db5dc388"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Pace Ventures: Why we invested in Clearly
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://smart-freight-centre-media.s3.amazonaws.com/documents/GLEC_FRAMEWORK_v3_UPDATED_02_04_24.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Read GLEC Framework
                    </a>
                  </p>
                </AnimatedDiv>
                <motion.div
                  className="about-us-page-request-demo"
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <RequestDemoButton parentPage="About" />
                </motion.div>
              </div>
            </div>
            <div
              className={`${
                isMobile ? 'pure-u-24-24' : 'pure-u-sm-24-24 pure-u-xl-13-24'
              } right`}
            >
              {isBelowLaptop && (
                <div className="sub-header world-map-header">
                  <AnimatedDiv>
                    <h2>Active in 9 countries</h2>
                  </AnimatedDiv>
                </div>
              )}
              <div className="word-map-back">
                <AnimatedDiv>
                  {/* <WorldMap /> */}
                  {/* throwIfNamespace: false */}
                  {!isMobile ? (
                    <img src={assets.worldMap} alt="World Map" />
                  ) : (
                    <img src={assets.worldMobileMap} alt="World Map" />
                  )}
                </AnimatedDiv>
              </div>
              {/* <div className="stats desktop ">
                <AnimatedDiv>
                  {!isMobile ? <Stats /> : <StatsMobile />}
                </AnimatedDiv>
              </div> */}
            </div>
          </div>
        </div>
        {/* <motion.div
        className="about-us-page-request-demo"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: -170 }}
        transition={{ duration: 0.5 }}
      >
        <RequestDemoButton parentPage='About' />
      </motion.div> */}

        <div className="illustration-section">
          <div className="pure-u-1 branching-svg">
            <AnimatedDiv>
              {!isBelowLaptop && (
                <img
                  src={assets.branching}
                  alt="branching"
                  loading="lazy"
                ></img>
              )}
              {isBelowLaptop && (
                <img
                  src={assets.branchingAlternative}
                  alt="branching"
                  loading="lazy"
                ></img>
              )}
            </AnimatedDiv>
          </div>
          <div className="pure-u-1 main-illustration">
            <div className="pure-u-xl-8-24 description">
              <AnimatedDiv>
                <p>
                  <span>
                    Clearly creates a rich data ecosystem collected from each
                    actor that has an impact on road transportation and the
                    emissions it creates.
                  </span>
                </p>
                <p>
                  By blending this data and enriching it, we enable and empower
                  all stakeholders in the industry to work individually and
                  together to optimally reach net zero emissions.
                </p>
              </AnimatedDiv>
            </div>
            <AnimatedDiv>
              {!isBelowLaptop && (
                <img
                  src={assets.mainIllustration}
                  alt="illustration big"
                  loading="lazy"
                ></img>
              )}
            </AnimatedDiv>
            <AnimatedDiv>
              {isBelowLaptop && (
                <img
                  src={assets.mainIllustrationAlternative}
                  alt="illustration big"
                  loading="lazy"
                ></img>
              )}
            </AnimatedDiv>
          </div>
        </div>
      </motion.div>
    </>
  );
}
