import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './Tailwind.css';
import './App.scss';

import Header from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Cookie from './components/Cookie';
import Routes from './Routes';
import { ParallaxProvider } from 'react-scroll-parallax';
import GoogleAnalyticsProvider from './providers/GoogleAnalyticsProvider';
import { logPageView } from './analytics';
import AnalyticsTracker from './analytics/pageTracker';

function App() {
  const [isPreLoaderLoaded, setPreLoaderStatus] = useState(false);
  const [trackingEnabled, setTrackingEnabled] = useState(false);

  useEffect(() => {
    const isLoaded = setTimeout(() => {
      setPreLoaderStatus(true);
      // Log the initial page view
      if (trackingEnabled) {
        logPageView();
      }
    }, 5000);

    return () => clearTimeout(isLoaded);
  }, [trackingEnabled]);

  return (
    <ParallaxProvider>
      <GoogleAnalyticsProvider setTrackingEnabled={setTrackingEnabled}>
        <Router>
          <Header isPreLoaderLoaded={isPreLoaderLoaded} />
          <ScrollToTop />
          <Routes isPreLoaderLoaded={isPreLoaderLoaded} />
          <Footer isPreLoaderLoaded={isPreLoaderLoaded} />
          <Cookie isPreLoaderLoaded={isPreLoaderLoaded} />
          <AnalyticsTracker trackingEnabled={trackingEnabled} />
        </Router>
      </GoogleAnalyticsProvider>
    </ParallaxProvider>
  );
}

export default App;
