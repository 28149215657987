import { useMutation } from '@tanstack/react-query';
import emailjs from 'emailjs-com';

// Context
import { useLeadCaptureContext } from '../../context/leads/useLeadCaptureContext';
import { useReductionsCalculatorsContext } from '../../context/calculators/reductionsCalculator';

// Analytics
import { logEvent } from '../../analytics';

// Hooks
import ScreenDetect from '../ScreenDetect';

interface ContactFormDetailShape {
  // Contact Details
  name: string;
  emailAddress: string;
  companyName: string;
  jobTitle: string;
  fleetSize: string;
}

interface GoogleSheetDetailsShape extends ContactFormDetailShape {
  // Vehicle inputs
  region: string;
  unit: string;
  currency: string;
  vehicleType: string;
  fuelType: string;
  numberOfVehicles: number;
  annualDistancePerVehicle: number;

  // Reduction potential
  costReduction: string;
  emissionsReduction: string;
}
interface LeadCaptureData extends ContactFormDetailShape {
  // Hygiene
  hasAcceptedTerms: boolean;
  formErrorMessage: string;

  // Submitted form
  hasSubmittedForm: boolean;
  isSavingLeadContactDetails: boolean;
}

interface LeadCaptureOperations {
  checkEmailValid: (email: string) => boolean;
  checkFormValid: () => boolean;
  onUpdateContactForm: (
    inputField: string,
    inputValue: string | boolean
  ) => void;
  onSubmitContactDetailsForm: () => void;
}

interface LeadCaptureResponse {
  data: LeadCaptureData;
  operations: LeadCaptureOperations;
}

const useLeadCapture = (): LeadCaptureResponse => {
  const pageLoadTime = Date.now();

  // Constants
  const SAVE_LEAD_DATA_API =
    'https://mbmm0k5dze.execute-api.eu-north-1.amazonaws.com/prod';
  //

  // Context
  const { reductionsCalculatorsState } = useReductionsCalculatorsContext();
  const { leadCaptureState, updateleadCaptureState } = useLeadCaptureContext();

  //

  // Hooks
  const { isMobile } = ScreenDetect();

  //

  // Variables
  const {
    name,
    emailAddress,
    companyName,
    jobTitle,
    fleetSize,
    hasAcceptedTerms,

    // Submitted form
    hasSubmittedForm,
    formErrorMessage,
  } = leadCaptureState;

  const {
    // Vehicle inputs
    region,
    unit,
    currency,
    vehicleType,
    fuelType,
    numberOfVehicles,
    annualDistancePerVehicle,

    // Reduction potential
    costReduction,
    emissionsReduction,
  } = reductionsCalculatorsState;

  //

  // Mutations
  const {
    mutate: saveLeadContactDetails,
    isPending: isSavingLeadContactDetails,
  } = useMutation({
    mutationFn: async (googleSheetDetails: GoogleSheetDetailsShape) => {
      const response = await fetch(SAVE_LEAD_DATA_API, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_GATEWAY_API_KEY,
        },
        body: JSON.stringify(googleSheetDetails),
      });

      if (!response.ok) {
        throw new Error('Failed to save lead data');
      }

      return response.json();
    },
  });

  //

  // Handlers
  const onUpdateContactForm = (
    inputField: string,
    inputValue: string | boolean
  ) => {
    logEvent('Lead Capture', `${inputField} Input Changed`, inputValue);
    updateleadCaptureState({ [inputField]: inputValue });
  };

  const onSubmitContactDetailsForm = () => {
    if (pageLoadTime) {
      const elapsedTime = Date.now() - pageLoadTime;
      logEvent(
        'Page Time (Lead Details)',
        'Time Before Submit',
        'Elapsed Time',
        elapsedTime
      );
    }

    logEvent('Lead Form', 'Submit Button Clicked', 'Submit Button');

    if (checkFormValid()) {
      //

      // Call API
      const googleSheetDetails: GoogleSheetDetailsShape = {
        // Contact details
        name,
        emailAddress,
        companyName,
        jobTitle,
        fleetSize,

        // Vehicle inputs
        region: region.label,
        unit: unit.label,
        currency: currency.label,
        vehicleType: vehicleType.label,
        fuelType: fuelType.label,
        numberOfVehicles,
        annualDistancePerVehicle,

        // Reduction potential
        costReduction: `${costReduction}%`,
        emissionsReduction: `${emissionsReduction}%`,
      };

      // Send acknowledgment email with EmailJS
      sendEmail({ name, emailAddress });

      // Optimistically update state
      // Set submitted form and clear form state
      updateleadCaptureState({
        hasSubmittedForm: true,
      });

      saveLeadContactDetails(googleSheetDetails, {
        onSuccess: () => {
          // Clear form state
          updateleadCaptureState({
            name: '',
            emailAddress: '',
            companyName: '',
            jobTitle: '',
            fleetSize: '',
            formErrorMessage: '',
          });
        },
        onError: () => {
          // Roll back to show the form on error
          updateleadCaptureState({
            hasSubmittedForm: false,
          });
        },
      });

      // On mobile - scroll the Thank you message into view (via id="rpc-recalculate-button" which is just above)
      if (isMobile) {
        const rpcRecalculateButton = document.getElementById(
          'rpc-recalculate-button'
        );
        if (rpcRecalculateButton) {
          // Scroll into view with 50px space to the top
          rpcRecalculateButton.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    } else {
      // Set error message
      updateleadCaptureState({
        formErrorMessage: 'Please fill in all fields and accept the terms',
      });
    }
  };

  //

  // Methods

  const sendEmail = ({ name, emailAddress }) => {
    const serviceID = process.env.REACT_APP_MAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_MAILJS_LEAD_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_MAILJS_USER_ID;

    // emailjs initialised with user ID
    emailjs.init(publicKey);

    const templateParams = {
      subject: 'Thank You for Your Submission',
      name: name,
      to_mail: emailAddress,
    };

    emailjs
      .send(serviceID, templateID, templateParams)
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      });
  };

  const checkEmailValid = (email: string): boolean => {
    const emailRegex = /\S+@\S+\.\S+/;
    const hasTwoLetteresAfterDot = email.split('.').pop().length > 1;
    return emailRegex.test(email) && hasTwoLetteresAfterDot;
  };

  const checkFormValid = (): boolean => {
    if (
      name &&
      emailAddress &&
      companyName &&
      jobTitle &&
      fleetSize &&
      hasAcceptedTerms &&
      checkEmailValid(emailAddress)
    ) {
      return true;
    }

    return false;
  };

  return {
    data: {
      // State
      name,
      emailAddress,
      companyName,
      jobTitle,
      fleetSize,

      // Hygeine
      hasAcceptedTerms,
      formErrorMessage,

      // Submitted
      hasSubmittedForm,
      isSavingLeadContactDetails,
    },
    operations: {
      // Validation
      checkEmailValid,
      checkFormValid,

      // Update
      onUpdateContactForm,

      // Submit
      onSubmitContactDetailsForm,
    },
  };
};

export default useLeadCapture;
