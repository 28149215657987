import React from 'react';
import { motion } from 'framer-motion';
import { assets } from '../assets/assets';
export default function ErrorPage() {
  return (
    <>
      <motion.div
        className="container error-page pure-g"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="pure-u-16-24 text">
          <h1>404</h1>
          <p>Page not found</p>
        </div>
        <div className="pure-u-lg-8-24 img">
          <img
            src={assets.pageNotFound}
            alt="car-stooped-background"
            loading="lazy"
          ></img>
        </div>
      </motion.div>
    </>
  );
}
