import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as XMark } from '../assets/svgs/xMark.svg';
import { ReactComponent as AngleUp } from '../assets/svgs/angleUp.svg';
import { ReactComponent as AngleDown } from '../assets/svgs/angleDown.svg';
import SimpleRequestDemoButton from '../components/Buttons/SimpleRequestDemoButton';

function TabsPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [listTabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetch('./datasets/tabs.json')
      .then((response) => response.json())
      .then((data) => setTabs(data.tabs));
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    navigate(`/${listTabs[index].page}`);
  };

  const currentTab = listTabs.find(
    (tab) => `/${tab.page}` === location.pathname
  );

  function isBelowLaptopWidth() {
    return window.innerWidth < 1280;
  }

  //checking device width for navigation switch
  const [isBelowLaptop, setisBelowLaptop] = useState(isBelowLaptopWidth());

  useEffect(() => {
    if (currentTab) {
      setActiveTab(listTabs.indexOf(currentTab));
    }
  }, [currentTab, listTabs]);

  useEffect(() => {
    function handleWindowResize() {
      setisBelowLaptop(isBelowLaptopWidth());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="container pure-g tabs">
      <div className="pure-u-1 home-navigator">
        <Link to="/#homepage-tab-navigator">
          Back to the story <XMark />
        </Link>
      </div>
      {listTabs.map((tab, index) => (
        <div
          key={index}
          className={`pure-u-1 tab-item${index === activeTab ? ' active' : ''}`}
        >
          <div
            className={`pure-g tab-activator link`}
            onClick={() => handleTabClick(index)}
          >
            <span
              className={`${isBelowLaptop ? 'pure-u-2-24' : 'pure-u-1-24'}`}
            >
              {index + 1}
            </span>
            <a
              className={`${isBelowLaptop ? 'pure-u-21-24' : 'pure-u-22-24'}`}
              onClick={() => handleTabClick(index)}
            >
              {tab.name}
            </a>
            <span
              className={`${
                isBelowLaptop ? 'pure-u-1-24' : 'pure-u-1-24'
              } pinch-out-icon link`}
              onClick={() => handleTabClick(index)}
            >
              <AngleDown />
              <AngleUp />
            </span>
          </div>
          {tab.page === currentTab?.page && (
            <>
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="tab-content pure-u-23-24 tabs-container"
              >
                <div className="tabs-content-wrapper tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-max-w-full tw-mx-auto tw-gap-8 tw-justify-between">
                  {/* Text content */}
                  <div className="tabs-child-content-text tw-w-full lg:tw-w-[40%] tw-flex-none tw-box-border tw-p-4">
                    <div
                      dangerouslySetInnerHTML={{ __html: tab.content }}
                      className="tw-mb-8 [&>p]:tw-mb-4"
                    />

                    <SimpleRequestDemoButton />
                  </div>

                  {/* Image content */}
                  <div
                    className={`tab-content-image tw-w-full lg:tw-w-[50%] tw-flex-none tw-box-border tw-p-4 ${
                      isBelowLaptop ? 'tw--mt-8 tw-py-8 tw-px-0' : 'tw--mt-20'
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: isBelowLaptop
                        ? tab.image.mobile
                        : tab.image.desktop,
                    }}
                  />
                </div>
              </motion.div>
            </>
          )}
        </div>
      ))}
      <div className="pure-u-1 home-navigator">
        <Link end to="/#homepage-tab-navigator" smooth>
          Back to the story <XMark />
        </Link>
      </div>
    </div>
  );
}
export default TabsPage;
